import { useState, useRef, useEffect } from 'react'
import { ForwardedRef, forwardRef } from 'react'
import { Label } from './Label'
import VendorCurrencyInput from 'react-currency-input-field'
import { amountToCurrencyBaseAmount, baseAmountToCurrencyAmount, getCurrencySymbol } from '../Money'
import classNames from 'classnames'
import { createEvent } from 'utilities/createEvent'
import { XCircleIcon } from '@heroicons/react/solid'
import { Country, default as VendorPhoneInput } from 'react-phone-number-input'
import { DatePicker } from './DatePicker'
import { DateTime } from 'luxon'

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string
  name?: string
  type?: string
  value: string
  disabled?: boolean
  maxlength?: number
  min?: number
  max?: number
  hideLabel?: boolean
  readOnly?: boolean
  placeholder?: string
  description?: string
  onChange: (value) => any
  prefixIcon?: any
  suffix?: any
  size?: 'default' | 'sm'
  currency?: string
  onClick?: (e) => any
  onFocus?: (e) => any
  onBlur?: (e) => any
  className?: string
  ref?: ForwardedRef<any>
  canClear?: boolean
}

export const inputClasses =
  'px-3 py-1 focus:ring-violet focus:ring-1 shadow-sm block w-full text-sm border-gray-300 focus:border-gray-300 dark:bg-gray-700 dark:border-transparent dark:text-white disabled:bg-gray-100 disabled:cursor-not-allowed rounded-md h-9 text-gray-900'

export const CurrencyInput: React.FC<InputProps> = forwardRef((props, ref: ForwardedRef<any>) => {
  const newRef = useRef<HTMLInputElement>(null)
  const inputRef = ref || newRef
  const currency = props.currency || 'gbp'
  const [value, setValue] = useState(baseAmountToCurrencyAmount(props.value, currency))

  useEffect(() => {
    setValue(baseAmountToCurrencyAmount(props.value, currency))
  }, [props.value])

  const updateValue = (value) => {
    setValue(value)

    if (typeof props.onChange === 'function') {
      props.onChange(amountToCurrencyBaseAmount(value, currency))
    }
  }

  const handleValueChange = (value) => {
    if (!value) {
      updateValue('')
      return
    }

    const numberValue = value.replace(',', '.')

    if (Number.isNaN(Number(numberValue))) {
      updateValue('')
      return
    }

    updateValue(value)
  }

  return (
    <InputWrapper {...props}>
      <VendorCurrencyInput
        name={props.name}
        data-testid="currency-input"
        onValueChange={handleValueChange}
        decimalScale={2}
        value={value}
        allowNegativeValue={false}
        ref={inputRef}
        className={classNames(
          props.prefixIcon && 'pl-10',
          props.size === 'sm' && ' py-1',
          inputClasses,
          props.className
        )}
        disabled={props.disabled}
        prefix={getCurrencySymbol(currency)}
        disableGroupSeparators={true}
        onBlur={props.onBlur}
      />
    </InputWrapper>
  )
})

const InputWrapper = (props) => {
  return (
    <div>
      <Label htmlFor={props.name} hide={props.hideLabel || !props.label} text={props.label} />

      <div className={(!props.hideLabel && props.label ? 'mt-1' : '') + ' relative'}>
        {props.prefixIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {props.prefixIcon({
              className: 'h-5 w-5 text-gray-400',
              'aria-hidden': true,
            })}
          </div>
        )}
        {props.children}
        {props.suffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <span className="text-gray-500 text-sm">{props.suffix}</span>
          </div>
        )}
        {props.canClear && props.value !== '' && (
          <div
            className={`absolute inset-y-0 right-0 ${
              props.type === 'date' ? 'pr-8' : 'pr-3'
            } flex items-center`}
          >
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 p-1"
              onClick={() =>
                props.onChange(
                  createEvent({
                    name: props.name,
                    value: '',
                  })
                )
              }
            >
              <XCircleIcon className="w-5 h-5" />
            </button>
          </div>
        )}
        {props.description && <p className="mt-2 text-sm text-gray-500">{props.description}</p>}
      </div>
    </div>
  )
}

export const Input: React.FC<InputProps> = forwardRef(
  (props: InputProps, ref: ForwardedRef<any>) => {
    return (
      <InputWrapper {...props}>
        <input
          ref={ref}
          type={props.type ?? 'text'}
          name={props.name}
          id={props.name}
          maxLength={props.maxlength}
          min={props.min ?? 0}
          max={props.max}
          className={classNames(
            props.prefixIcon && 'pl-10',
            props.suffix && 'pr-10',
            props.size === 'sm' && ' py-1',
            inputClasses,
            props.className
          )}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
          value={props.value}
          readOnly={props.readOnly}
          step={props.step}
        />
      </InputWrapper>
    )
  }
)

export const FormikCurrencyInput = forwardRef(
  ({ onChange: parentOnChange, ...props }: any, ref: any) => {
    const handleOnChange = (value) => {
      parentOnChange(createEvent({ value: value || '', name: props.name }))
    }

    return <CurrencyInput name={props.name} onChange={handleOnChange} ref={ref} {...props} />
  }
)

export const PhoneInput: React.FC<InputProps & { countryCode?: Country }> = forwardRef(
  (props, ref: ForwardedRef<any>) => {
    const newRef = useRef<HTMLInputElement>(null)
    const inputRef = ref || newRef
    const countryCode = props.countryCode || 'GB'

    return (
      <InputWrapper {...props}>
        <VendorPhoneInput
          onChange={props.onChange}
          value={props.value}
          ref={inputRef}
          inputComponent={Input}
          defaultCountry={countryCode}
          className="react-phone-input"
        />
      </InputWrapper>
    )
  }
)

export const FormikPhoneInput = forwardRef(
  ({ onChange: parentOnChange, ...props }: any, ref: any) => {
    const handleChange = (value) => {
      parentOnChange(createEvent({ value: value || '', name: props.name }))
    }

    return <PhoneInput name={props.name} onChange={handleChange} ref={ref} {...props} />
  }
)

export const DateInput: React.FC<InputProps> = forwardRef((props, ref: ForwardedRef<any>) => {
  const newRef = useRef<HTMLInputElement>(null)
  const inputRef = ref || newRef

  return (
    <InputWrapper {...props}>
      <DatePicker
        onChange={props.onChange}
        value={new Date(props.value)}
        minDate={props.min ? DateTime.fromISO(props.min).toJSDate() : undefined}
        ref={inputRef}
      />
    </InputWrapper>
  )
})
