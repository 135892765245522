import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from './Text'
import { Card } from './Card'

interface FeatureCardProps {
  title: string | JSX.Element
  subtitle?: string
  truncateTitle?: boolean
  iconForeground?: string
  iconBackground?: string
  icon?: (_: any) => React.ReactNode
  image?: string
  links?: any[]
  children?: any
  isLinked?: boolean
  className?: string
}

interface LinkedFeatureCardProps extends FeatureCardProps {
  to: string
}

export const LinkedFeatureCard: React.FC<LinkedFeatureCardProps> = (props) => (
  <Link to={props.to} className="flex-grow focus:ring-1 focus:ring-violet">
    <FeatureCard {...props} className="hover:border-violet" isLinked />
  </Link>
)

export const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  iconForeground,
  iconBackground,
  icon = null,
  image = null,
  links = [],
  children = null,
  className = '',
}) => (
  <Card className={`h-full w-full flex flex-col ${className}`}>
    <div className={`flex space-x-2 items-center`}>
      {icon && (
        <div
          className={`rounded-lg p-1.5 ring-4 ring-white dark:ring-gray-700 ${iconBackground} ${iconForeground}`}
        >
          {icon({
            className: 'w-4 h-4',
          })}
        </div>
      )}
      {image && (
        <img src={image} alt="" className="w-8 h-8 rounded-lg bg-gray-100 dark:bg-gray-800" />
      )}
      <div>
        <Text type="headline" className="truncate">
          {title}
        </Text>
      </div>
    </div>

    <div className="space-y-4 flex-1">
      {children && (
        <Text type="caption" className="h-full">
          {children}
        </Text>
      )}

      {links.length > 0 && (
        <ul className="border-t border-border/60 pt-4 font-medium text-gray-700 dark:text-gray-300 space-y-2">
          {links
            .filter((link) => !!link)
            .map((link) => (
              <li key={link.to}>
                <Link className="hover:underline" to={link.to}>
                  {link.label}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </div>
  </Card>
)
