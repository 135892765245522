import { useDeleteCustomer } from 'api/Customers'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { Text } from 'ui/components/Text'
import { ConfirmationModal } from 'ui/Modal'

export const DeleteConfirmation = ({ clientId, onClose }) => {
  const { isLoading, mutate } = useDeleteCustomer(clientId)
  const notification = useNotificationContext()

  const handleDelete = () => {
    mutate(null, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Client deleted"
            description="All the data associated with this client has been anonymised."
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        onClose()

        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <ConfirmationModal
      isOpen={clientId !== null}
      title="Delete customer"
      onClose={() => onClose()}
      confirmButtonLabel="Delete"
      onConfirm={handleDelete}
      confirming={isLoading}
      variant="danger"
    >
      <div className="space-y-4">
        <Text type="caption">Are you sure you want to delete this customer?</Text>
        <Text type="caption">
          All their personal data will be anonymised, and this cannot be undone.
        </Text>
      </div>
    </ConfirmationModal>
  )
}
