import { Link } from 'react-router-dom'
import { Button } from 'ui/components/Button'
import { ScreenLayout } from 'ui/components/ScreenLayout'
import { Text } from 'ui/components/Text'

export const NotFound404 = () => {
  return (
    <ScreenLayout title="Page not found">
      <div className="space-y-8">
        <Text type="caption">Sorry, we couldn't find the page you were looking for.</Text>

        <Button label="Back home" as={Link} to="/" />
      </div>
    </ScreenLayout>
  )
}
