import { Text } from './Text'

export const DescriptionItem = ({ label, children }) => (
  <>
    <dt>
      <Text type="label">{label}</Text>
    </dt>
    <dd className="mt-1 text-sm text-gray-700">{children}</dd>
  </>
)
