import { ReactNode, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { NestedLink as NestedLinkComponent } from './NestedLink'
import classNames from 'classnames'
import { useSidebar } from 'layouts/SidebarLayout/contexts'
import { Text } from './Text'

export interface PreviousPage {
  title: string
  path: string
}

export interface NestedLink {
  title: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  path: string
  isActive: boolean
}

export enum paddingXOptions {
  md = 'md',
  sm = 'sm',
}

interface ScreenLayoutProps {
  title: string
  breadcrumbTitle?: string
  underTitle?: ReactNode // TODO: refactor when we have a better idea of what we want to do with this
  rightOfTitle?: ReactNode
  children: ReactNode
  previousPages?: PreviousPage[]
  nestedLinks?: NestedLink[]
  noBreadcrumb?: boolean
  paddingX?: paddingXOptions
}

export const ScreenLayout: React.FC<ScreenLayoutProps> = ({
  title,
  breadcrumbTitle = null,
  rightOfTitle,
  underTitle,
  children,
  previousPages = [],
  nestedLinks = [],
  noBreadcrumb = false,
  paddingX = paddingXOptions.md,
}: ScreenLayoutProps) => {
  const { setTitle, setPreviousPages } = useSidebar()
  const jsonifiedPreviousPages = useMemo(() => JSON.stringify(previousPages), [previousPages])

  useEffect(() => {
    setTitle(breadcrumbTitle ?? title)
    setPreviousPages(previousPages)
  }, [setTitle, title, setPreviousPages, title, jsonifiedPreviousPages])

  useEffect(() => {
    return () => {
      setTitle(null)
      setPreviousPages([])
    }
  }, [])

  const containerClasses = ['style-reset', 'max-w-7xl', 'mx-auto', 'w-full', 'p-4', 'pb-20']

  switch (paddingX) {
    case paddingXOptions.md:
      containerClasses.push('px-10')
      break

    case paddingXOptions.sm:
    default:
      containerClasses.push('px-4')
      break
  }

  return (
    <div className={classNames(containerClasses)}>
      <div className="flex items-center">
        <div className="flex-grow py-2">
          <Text type="title2">{title}</Text>
        </div>
        {rightOfTitle && <div className="flex-shrink-0">{rightOfTitle}</div>}
      </div>

      {underTitle && <div className="-mt-3 mb-6">{underTitle}</div>}

      {nestedLinks.length > 0 && (
        <NestedLinks>
          {nestedLinks.map(({ title, icon, path, isActive }, i) => (
            <NestedLinkComponent
              title={title}
              icon={icon}
              path={path}
              isActive={isActive}
              key={i}
            />
          ))}
        </NestedLinks>
      )}

      {children}
    </div>
  )
}

interface BreadcrumbProps {
  title: string
  previousPages: PreviousPage[]
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, previousPages }) => {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {previousPages.map(({ title: linkTitle, path }) => (
          <li key={path}>
            <div className="flex items-center">
              <Link to={path} className="mr-4 text-sm font-medium text-gray-900 dark:text-gray-400">
                {linkTitle}
              </Link>
              {title && (
                <svg
                  viewBox="0 0 6 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="h-5 w-auto text-gray-300 dark:text-gray-500"
                >
                  <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                </svg>
              )}
            </div>
          </li>
        ))}

        <li className="text-sm">
          <span aria-current="page" className="font-medium text-gray-500 dark:text-gray-300">
            {title}
          </span>
        </li>
      </ol>
    </nav>
  )
}

const NestedLinks = ({ children }) => (
  <div className="border-b border-gray-300 dark:border-gray-700 font-medium overflow-x-auto">
    <div className="flex text-sm text-gray-600">{children}</div>
  </div>
)

export const ScreenLayoutSection = ({ children }) => <div className="pt-6 lg:pt-10">{children}</div>
