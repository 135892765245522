interface Props {
  children: React.ReactNode
}

export const FeatureCardGrid: React.FC<Props> = ({ children }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 text-sm mt-7">
      {children}
    </div>
  )
}
