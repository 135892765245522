type Types = 'caption' | 'caption2' | 'headline' | 'label' | 'title1' | 'title2' | 'title3'

interface Props {
  type: Types
  className?: string
  children: any
}
export const Text: React.FC<Props> = ({ type, className: overrideClassNames = '', children }) => {
  let classNames = ['block']

  const overrides = overrideClassNames.split(' ')

  switch (type) {
    case 'title1':
      classNames.push('text-4xl font-semibold')
      break
    case 'title2':
      classNames.push('text-3xl font-semibold')
      break
    case 'title3':
      classNames.push('text-xl font-semibold')
      break
    case 'headline':
      classNames.push('font-semibold text-lg')
      break
    case 'caption':
      classNames.push('text-sm text-muted-foreground')
      break
    case 'caption2':
      classNames.push('text-xs text-muted-foreground/80')
      break
    case 'label':
      classNames.push('text-sm font-medium dark:text-white text-gray-700')
      break
  }

  return <span className={[...classNames, ...overrides].join(' ')}>{children}</span>
}
