import { default as IntercomJs, update } from '@intercom/messenger-js-sdk'
import { InitType } from '@intercom/messenger-js-sdk/types'
import { useGetUserSites } from 'api/Users'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useIntercomUserHash, useUser } from 'hooks/UseUser'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const Intercom = () => {
  const { sub: userId, email, given_name: firstName, family_name: lastName } = useUser()
  const intercomUserHash = useIntercomUserHash()
  const { data: { data: sites = [] } = {} } = useGetUserSites(userId, {
    enabled: !!userId,
    cacheTime: Infinity,
    staleTime: 60 * 60 * 1000, // 60 minutes
  })
  const featureFlags = useActiveFeatureFlags()
  const storageSiteId = sessionStorage.getItem('activeSiteId')
  const [currentSite, setCurrentSite] = useState(undefined)
  const { pathname } = useLocation()

  useEffect(() => {
    if (storageSiteId && sites.length > 0 && storageSiteId !== currentSite?.id) {
      const foundSite = sites.find((site) => site.id === storageSiteId)
      setCurrentSite(foundSite)
      update({ recent_site_name: foundSite.name })
    }
  }, [storageSiteId, sites, featureFlags])

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update')
    }
  }, [pathname])

  if (
    !import.meta.env.VITE_APP_INTERCOM_APP_ID ||
    userId === undefined ||
    currentSite === undefined
  ) {
    return null
  }

  const intercomOptions: InitType = {
    app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
    region: 'eu',
    company: {
      company_id: currentSite.id,
      name: currentSite.name,
    },
  }

  if (!email.endsWith('@try.be')) {
    intercomOptions.user_id = userId
    intercomOptions.user_hash = intercomUserHash
    intercomOptions.name = [firstName, lastName].filter(Boolean).join(' ')
    intercomOptions.email = email
    intercomOptions.recent_site_name = currentSite.name
    intercomOptions.companies = sites.map((site) => ({
      company_id: site.id,
      name: site.name,
    }))
  }

  IntercomJs(intercomOptions)

  return null
}
