import { LoginIcon } from '@heroicons/react/solid'
import { useSwitchBack } from 'api/LoginAsSite'
import { useCurrentSite } from 'contexts/SiteConfig'
import { useIsSystemUser } from 'hooks/UseUser'
import { useAuth } from 'react-oidc-context'
import { Button } from 'ui/components/Button'
import { useNotificationContext } from 'ui/components/Notification'

export const LoggedInAsSiteIndicator = () => {
  const site = useCurrentSite()
  const isSystemUser = useIsSystemUser()
  const { mutate } = useSwitchBack()
  const notifications = useNotificationContext()
  const auth = useAuth()

  if (!isSystemUser) {
    return null
  }

  const handleClick = () => {
    mutate(null, {
      onSuccess: () => {
        auth.signinRedirect({ state: '/' })
      },
      onError: (e: any) => {
        notifications.notifyApiError(e)
      },
    })
  }

  return (
    <div className="space-y-2 mb-2 bg-grape-500 border border-dashed border-violet text-xs p-2 rounded-md text-white">
      <div>You're currently logged in as</div>
      <div className="font-medium text-sm truncate" title={site?.name}>
        {site?.name}
      </div>
      <Button
        onClick={() => handleClick()}
        fullWidth
        leadingIcon={LoginIcon}
        label="Switch back"
        size="xs"
        variant="info"
      />
    </div>
  )
}
