import classNames from 'classnames'
import { Text } from '../Text'

interface LabelProps {
  text?: string
  htmlFor?: string
  hide?: boolean
  required?: boolean
  className?: string
  children?: string
}

export const Label: React.FC<LabelProps> = ({
  text,
  htmlFor,
  hide,
  required,
  children,
  className,
}) => (
  <label htmlFor={htmlFor} className={classNames(hide ? 'sr-only' : 'block', className)}>
    <div className="flex items-center justify-between">
      <div>
        {children && <Text type="label">{children}</Text>}
        {text && <Text type="label">{text}</Text>}
      </div>
      {required && <Text type="caption2">Required</Text>}
    </div>
  </label>
)
