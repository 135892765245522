import { Text } from 'ui/components/Text'

export const Section = ({ title, button = null, children }) => (
  <div>
    <div className="flex justify-between items-center p-3">
      <Text type="title3">{title}</Text>
      {button}
    </div>

    {children}
  </div>
)
