import { Text } from '../Text'
interface H2Props {
  /** The contents of the H1 */
  children: any
  /** Whether the text should be truncated or wrap */
  truncate?: boolean
}

/**
 * @deprecated Just use <Text> instead
 */
export const H2: React.FC<H2Props> = ({ children, truncate = true }) => (
  <Text type="title3">{children}</Text>
)
