import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from 'react-query'
import { MembershipTypesApi } from '@trybeapp/sdk.customer'
import {
  CreateSiteMembershipType,
  ListMembershipTypesRequest,
  MembershipTypesApi as MembershipTypesApiTs,
  UpdateMembershipType,
} from '@trybeapp/sdk'
import { useOrganisationId } from 'contexts/OrganisationConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new MembershipTypesApi()
const tsApi = new MembershipTypesApiTs(apiConfiguration)

export const useMembershipTypes = (params = {}, options = {}) => {
  return useQuery(['membershipTypes', params], () => tsApi.listMembershipTypes(params), {
    refetchInterval: 600000, // every 10 minutes
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useSiteMembershipTypes = (options = {}): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['membershipTypes', { siteId }],
    async () => tsApi.listSiteMembershipTypes({ siteId }),
    options
  )
}

export const useListSingleSiteMembershipType = (
  siteId: string,
  membershipTypeId: string,
  options = {}
) => {
  return useQuery(
    ['singleSitemembershipType', siteId, membershipTypeId],
    async () =>
      await tsApi.listSingleSiteMembershipType({
        siteId,
        membershipTypeId,
      }),
    {
      ...options,
    }
  )
}

export const useCreateSiteMembershipType = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (createSiteMembershipType: CreateSiteMembershipType) =>
      tsApi.createSiteMembershipType({
        siteId,
        createSiteMembershipType: createSiteMembershipType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('membershipTypes')
      },
    }
  )
}

export const useDeleteSiteMembershipType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ siteId, membershipTypeId }: { siteId: string; membershipTypeId: string }) =>
      tsApi.deleteSiteMembershipType({ siteId, membershipTypeId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('membershipTypes')
      },
    }
  )
}

export const usePagedMembershipTypes = (params = {}, options = {}) => {
  const [page] = usePageQueryParam()

  return useMembershipTypes({ page, ...params }, options)
}

export const useInfiniteMembershipTypes = (params: ListMembershipTypesRequest = {}, options = {}) =>
  useInfiniteQuery(['membershipTypes', { params }], fetchMembershipTypes, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage
    },
    ...options,
  })

const fetchMembershipTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]
  const response = await tsApi.listMembershipTypes({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useMembershipType = (membershipTypeId, options = {}) => {
  return useQuery(
    ['membershipType', membershipTypeId],
    async () => (await tsApi.getMembershipType({ membershipTypeId })).data,
    {
      ...options,
    }
  )
}

export const useOrderedMembershipTypes = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const { site = {} } = useSiteConfig()
  const { brand_id: brandId } = site

  return useQuery(
    ['membershipTypes', params, brandId],
    async () => await api.getOrderedMembershipTypes(params, brandId),
    {
      refetchInterval: 600000, // every 10 minutes
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}

export const useUpdateOrderedMembershipTypes = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()

  return useMutation(
    (membershipTypeIds: any) =>
      api.updateMembershipTypeOrder({
        membership_type_ids: membershipTypeIds,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['membershipTypes'])
      },
    }
  )
}

export const useCreateMembershipType = (options: UseQueryOptions = {}) => {
  const queryClient = useQueryClient()
  const organisationId = useOrganisationId()

  return useMutation(
    async (membershipType: { [index: string]: unknown }) =>
      await api.createMembershipType({
        createMembershipTypeRequest: {
          ...membershipType,
          organisation_id: organisationId,
        },
      }),
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries('membershipTypes')
        if (options.onSuccess) {
          options.onSuccess(result)
        }
      },
    }
  )
}

export const useUpdateMembershipType = (membershipTypeId, options: UseQueryOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (membershipType: any) => {
      const values = { ...membershipType }

      if (typeof membershipType.minimum_start_date === 'object') {
        values.minimum_start_date = toIsoDate(membershipType.minimum_start_date)
      }

      return await api.updateMembershipType(membershipTypeId, {
        updateMembershipTypeRequest: values,
      })
    },
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries('membershipTypes')
        queryClient.invalidateQueries('membershipType')
        if (options.onSuccess) {
          options.onSuccess(result)
        }
      },
    }
  )
}

export const useUpdateMembershipTypeUsingNewSdk = (membershipTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (updateMembershipType: UpdateMembershipType) => {
      return await tsApi.updateMembershipType({
        membershipTypeId,
        updateMembershipType,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('membershipTypes')
        queryClient.invalidateQueries('membershipType')
      },
    }
  )
}

export const useDeleteMembershipType = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation((membershipTypeId) => api.archiveMembershipType(membershipTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('membershipTypes')
    },
    ...options,
  })
}
