import { useMemo, useRef, useState } from 'react'
import { useVoucherTypes } from 'api/VoucherTypes'
import { TYPE_VOUCHER } from 'constants/offeringTypes'
import { Wizard } from 'ui/components/Wizard'
import { useAddOrderItem } from 'api/OrderItems'
import { useCurrentOrder } from 'contexts/Order'
import { object, string, date as yupDate } from 'yup'
import { Formik, useField, useFormikContext } from 'formik'
import { FormikCurrencyInput, Input } from 'ui/components/Form/Input'
import { ConnectedField } from 'components/ConnectedField'
import { Textarea } from 'ui/components/Form/Textarea'
import { ApiErrorNotification, useNotificationContext } from 'ui/components/Notification'
import { DateTime } from 'luxon'
import { CustomDeliveryDateSelect } from 'ui/components/Form/CustomDeliveryDateSelect'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { RadioCards } from 'ui/components/Form/RadioCards/RadioCards'
import { GoogleAddressAutoComplete } from 'ui/components/Form/GoogleAddressAutocomplete'
import classNames from 'classnames'
import { FormikVoucherDeliveryOptionSelect } from 'ui/components/Form/VoucherDeliveryOptionSelect'
import { APIProvider } from '@vis.gl/react-google-maps'
import { inputClasses } from 'ui/components/Form/Input'

export const AddVoucherOrderItemWizard = ({ basketItem = {}, isOpen, onClose }) => {
  const { order: { id: orderId } = {} } = useCurrentOrder()
  const [activeStep, setActiveStep] = useState(0)
  const [selectedVoucher, setSelectedVoucher] = useState(null)
  const notification = useNotificationContext()
  const featureFlags = useActiveFeatureFlags()
  const formRef = useRef()

  const { mutate, isLoading } = useAddOrderItem()

  const deliveryOptions = [
    {
      id: 'physical',
      title: 'Mail',
      description: 'We will send the voucher to the recipient by mail',
    },
    {
      id: 'email',
      title: 'Email',
      description: 'We will send the voucher to the recipient by email',
    },
  ]

  const initialValuesWithDeliveryMethodValidation = {
    offering_id: '',
    offering_type: '',
    item_configuration: {
      recipient_name: '',
      recipient_email: '',
      predetermined_code: '',
      delivery_method_type: deliveryOptions[0].id,
      delivery_date: '',
      address_line1: '',
      address_line2: '',
      county: '',
      country: '',
      postcode: '',
      town: '',
      delivery_option_id: '',
      custom_message: '',
    },
  }

  const initialValues = {
    offering_id: '',
    offering_type: '',
    item_configuration: {
      recipient_name: '',
      recipient_email: '',
      predetermined_code: '',
      delivery_date: '',
      custom_message: '',
    },
  }

  const updatedValidationSchema = object({
    offering_id: string().required(),
    offering_type: string().required(),
    item_configuration: object({
      recipient_name: string().required('Name is required'),
      recipient_email: string().required('Must be a valid email').email(),
      delivery_date: yupDate(),
      address_1ine1: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string().required('Address line 1 is required'),
      }),
      address_1ine2: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string().required('Address line 1 is required'),
      }),
      town: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string(),
      }),
      county: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string().required('County is required'),
      }),
      country: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string().required('Country is required'),
      }),
      postcode: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string().required('Postcode is required'),
      }),
      delivery_option_id: string().when('item_configuration.delivery_method_type', {
        is: 'physical',
        then: string().required('Delivery option is required'),
      }),
      predetermined_code: string().matches(
        new RegExp('^\\w*$'),
        'Must only contain alphaneumeric characters'
      ),
      custom_message: string().max(500),
    }),
  })

  const originalValidationSchema = object({
    offering_id: string().required(),
    offering_type: string().required(),
    item_configuration: object({
      recipient_name: string().required('Name is required'),
      recipient_email: string().required('Must be a valid email').email(),
      delivery_date: yupDate(),
      predetermined_code: string().matches(
        new RegExp('^\\w*$'),
        'Must only contain alphaneumeric characters'
      ),
      custom_message: string().max(500),
    }),
  })

  const handleVoucherSelect = (voucher) => {
    setSelectedVoucher(voucher)
    setActiveStep(1)
  }

  const handleDetailsSubmit = (values) => {
    setActiveStep(2)
  }

  const handleClose = () => {
    formRef.current.handleReset()
    setSelectedVoucher(null)
    setActiveStep(0)
    onClose()
  }

  const handleFinish = async () => {
    formRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    mutate([orderId, values], {
      onError: (error) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })
  }

  const canGoToStep = (newStep, values) => {
    if (newStep > 0 && selectedVoucher === null) {
      return false
    }

    if (newStep === 2) {
      return canGoToStepWithRecipientValidation(values.values)
    }

    return true
  }

  const canGoToStepWithRecipientValidation = (values) => {
    if (
      values?.item_configuration?.recipient_email === '' ||
      values?.item_configuration?.recipient_name === ''
    ) {
      return false
    }
    return true
  }

  const canGoBackStep = (oldStep) => {
    if (oldStep < 0) {
      return false
    }

    return true
  }

  const handleBeforeStepChange = (newStep) => {
    if (newStep !== activeStep) {
      setActiveStep(newStep)
    }

    return true
  }

  return (
    <Formik
      initialValues={
        featureFlags.includes('voucher_delivery_method')
          ? initialValuesWithDeliveryMethodValidation
          : initialValues
      }
      validationSchema={
        featureFlags.includes('voucher_delivery_method')
          ? updatedValidationSchema
          : originalValidationSchema
      }
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {(values) => (
        <Wizard
          isOpen={isOpen}
          onClose={handleClose}
          onBeforeStepChange={handleBeforeStepChange}
          activeStep={activeStep}
          canGoToStep={(nextStep) => canGoToStep(nextStep, values)}
          canGoBackStep={canGoBackStep}
          onFinish={handleFinish}
          finishLoading={isLoading}
          finishButtonLabel={basketItem.id ? 'Update item' : 'Add item'}
        >
          <ChooseVoucherStep name="Choose voucher" onSelect={handleVoucherSelect} />
          {!featureFlags.includes('voucher_delivery_method') && (
            <SetVoucherOptionsStep name="Set details" selectedVoucher={selectedVoucher} />
          )}
          {featureFlags.includes('voucher_delivery_method') && (
            <SetVoucherOptionsStepWithoutDeliveryOptions
              name="Set details"
              onSelect={handleDetailsSubmit}
              selectedVoucher={selectedVoucher}
            />
          )}
          {featureFlags.includes('voucher_delivery_method') && (
            <ChooseDeliveryMethodStep
              name="Choose delivery method"
              selectedVoucher={selectedVoucher}
              deliveryOptions={deliveryOptions}
            />
          )}
        </Wizard>
      )}
    </Formik>
  )
}

const ChooseVoucherStep = ({ onSelect }) => {
  const { values, setValues } = useFormikContext()
  const { data: { data: vouchers = [] } = {}, isLoading } = useVoucherTypes({
    perPage: -1,
  })

  const selectedVoucher = useMemo(
    () => vouchers.find((voucher) => voucher.id === values.offering_id),
    [vouchers, values.offering_id]
  )

  const handleSelect = (voucher) => {
    const newValues = {
      ...values,
      offering_id: voucher.id,
      offering_name: voucher.name,
      offering_type: TYPE_VOUCHER,
    }

    if (voucher.customisable_amount === true) {
      newValues.item_configuration = newValues.item_configuration || {}
      newValues.item_configuration.amount = voucher.amount
    }

    setValues(newValues)
    onSelect(voucher)
  }

  return (
    <>
      <div className="font-bold mb-2">Choose voucher</div>

      <ItemsList items={vouchers} selectedId={selectedVoucher?.id} onSelect={handleSelect} />

      {isLoading && <LoadingVouchersSkeleton />}
    </>
  )
}

const ChooseDeliveryMethodStep = ({ deliveryOptions }) => {
  const { order: { currency } = {} } = useCurrentOrder()
  const { values, setFieldValue, errors, touched } = useFormikContext()
  const [{ value }] = useField('offering_name')
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(deliveryOptions[0])
  const [choiceSelected, setChoiceSelected] = useState(false)

  const handleDeliveryMethodChange = (value) => {
    setChoiceSelected(true)
    setSelectedDeliveryMethod(value)
    setFieldValue('item_configuration.delivery_method_type', value.id)
    if (value.id === 'email') {
      setFieldValue('item_configuration.address_line1', '')
      setFieldValue('item_configuration.address_line2', '')
      setFieldValue('item_configuration.town', '')
      setFieldValue('item_configuration.county', '')
      setFieldValue('item_configuration.postcode', '')
      setFieldValue('item_configuration.delivery_option_id', '')
    } else {
      setFieldValue('item_configuration.delivery_date', '')
    }
  }

  return (
    <>
      <div className="font-bold mb-2">Set details</div>
      <div className="text-sm text-gray-500 mb-2">{`You are setting details for voucher ${value}`}</div>
      <div className="space-y-4">
        <RadioCards
          options={deliveryOptions}
          value={selectedDeliveryMethod}
          onChange={(val) => {
            handleDeliveryMethodChange(val)
          }}
        />{' '}
      </div>
      {selectedDeliveryMethod.id === 'physical' && <PhysicalDeliveryDetails />}
      {selectedDeliveryMethod.id === 'email' && (
        <div className="my-4">
          <EmailDeliveryDetails />
        </div>
      )}
    </>
  )
}

const PhysicalDeliveryDetails = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext()
  const [showManaulInput, setShowManualInputs] = useState(false)
  const [showAddressLookup, setShowAddressLookup] = useState(true)
  const [showManualInputTooltip, setShowManualInputTooltip] = useState(true)

  const handleAddressChange = (addressProperties) => {
    let number = addressProperties.address_components.find((component) =>
      component.types.includes('street_number')
    )
    let street = addressProperties.address_components.find((component) =>
      component.types.includes('route')
    )
    let town = addressProperties.address_components.find((component) =>
      component.types.includes('postal_town')
    )
    let county = addressProperties.address_components.find((component) =>
      component.types.includes('administrative_area_level_2')
    )
    let postCode = addressProperties.address_components.find((component) =>
      component.types.includes('postal_code')
    )
    let country = addressProperties.address_components.find((component) =>
      component.types.includes('country')
    )

    setFieldValue('item_configuration.address_line1', `${number?.long_name}, ${street?.long_name}`)
    setFieldValue('item_configuration.town', town?.long_name)
    setFieldValue('item_configuration.address_line2', '')
    setFieldValue('item_configuration.county', county?.long_name)
    setFieldValue('item_configuration.country', country?.long_name)
    setFieldValue('item_configuration.postcode', postCode?.long_name)
    setShowManualInputs(true)
    setShowAddressLookup(false)
    setShowManualInputTooltip(false)
  }

  return (
    <div>
      <div className="py-2">
        <>
          {showAddressLookup && (
            <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
              <GoogleAddressAutoComplete onPlaceSelect={handleAddressChange} />
            </APIProvider>
          )}
          {showManualInputTooltip && (
            <p
              className="mt-2 text-gray-500 text-sm underline"
              onClick={() => {
                setShowManualInputs(true)
                setShowAddressLookup(false)
                setShowManualInputTooltip(false)
              }}
            >
              Enter an address manually
            </p>
          )}
        </>

        {showManaulInput && (
          <>
            <input
              name="address"
              placeholder="Address line 1"
              type="text"
              value={values.item_configuration.address_line1}
              onChange={(event) =>
                setFieldValue('item_configuration.address_line1', event.target.value)
              }
              className={classNames(inputClasses, 'w-full block my-2 rounded-md')}
            />
            <input
              name="address"
              placeholder="Address line 2"
              type="text"
              value={values.item_configuration.address_line2}
              onChange={(event) =>
                setFieldValue('item_configuration.address_line2', event.target.value)
              }
              className={classNames(inputClasses, 'w-full block my-2 rounded-md')}
            />
            <div className="grid md:grid-cols-2 grid-rows-2 sm:grid-cols-1">
              <input
                name="town"
                placeholder="Town"
                type="text"
                value={values.item_configuration.town}
                onChange={(event) => {
                  setFieldValue('item_configuration.town', event.target.value)
                }}
                className={classNames(inputClasses)}
              />
              <input
                name="county"
                placeholder="County"
                type="text"
                value={values.item_configuration.county}
                className={classNames(inputClasses)}
                onChange={(event) => setFieldValue('item_configuration.county', event.target.value)}
              />
              <input
                name="postcode"
                placeholder="Postcode"
                type="text"
                value={values.item_configuration.postcode}
                className={classNames(inputClasses)}
                onChange={(event) =>
                  setFieldValue('item_configuration.postcode', event.target.value)
                }
              />
              <input
                name="country"
                placeholder="Country"
                type="text"
                disabled
                value={values.item_configuration.country}
                className={classNames(inputClasses)}
              />
            </div>
          </>
        )}

        <div className="md:w-2/3 mt-4">
          <ConnectedField
            name="item_configuration.delivery_option_id"
            label="Delivery option"
            component={FormikVoucherDeliveryOptionSelect}
            onChange={(event) =>
              setFieldValue('item_configuration.delivery_option_id', event.target.value)
            }
          />
        </div>
      </div>
    </div>
  )
}

const EmailDeliveryDetails = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext()

  const handleSendImmediatelyChange = (value) => {
    if (value && values.item_configuration.delivery_date !== '') {
      setFieldValue('item_configuration.delivery_date', '')
    } else {
      setFieldValue('item_configuration.delivery_date', DateTime.now().plus({ days: 1 }).toJSDate())
    }
  }
  return (
    <div>
      <CustomDeliveryDateSelect
        dateValue={
          values.item_configuration.delivery_date !== ''
            ? values.item_configuration.delivery_date
            : DateTime.now().plus({ days: 1 }).toJSDate()
        }
        onChange={(date) => {
          setFieldValue('item_configuration.delivery_date', date)
        }}
        minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
        onSendImmediatelyChange={handleSendImmediatelyChange}
        sendImmediately={values.item_configuration.delivery_date === ''}
      />
    </div>
  )
}

const SetVoucherOptionsStep = () => {
  const { order: { currency } = {} } = useCurrentOrder()
  const [{ value }] = useField('offering_name')
  const [{ value: voucherTypeId }] = useField('offering_id')
  const { data: { data: vouchers = [] } = {} } = useVoucherTypes({
    perPage: -1,
  })

  const voucherType = useMemo(
    () => vouchers.find((voucher) => voucher.id === voucherTypeId),
    [vouchers, voucherTypeId]
  )

  return (
    <>
      <div className="font-bold mb-2">Set details</div>
      <div className="text-sm text-gray-500 mb-2">{`You are setting details for voucher  ${value}`}</div>
      <div className="flex flex-col space-y-4">
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Recipient name"
            name="item_configuration.recipient_name"
            component={Input}
            type="text"
            autoFocus
          />
        </div>
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Recipient email"
            name="item_configuration.recipient_email"
            component={Input}
            type="email"
          />
        </div>
        {voucherType && voucherType.customisable_amount && (
          <div className="w-3/4 flex-1 min-w-0">
            <ConnectedField
              label="Voucher amount"
              name="item_configuration.amount"
              helperText="Optionally change the amount of this voucher"
              component={FormikCurrencyInput}
              currency={currency}
              type="text"
              className="w-32"
            />
          </div>
        )}
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Custom voucher code"
            name="item_configuration.predetermined_code"
            helperText="Leave this blank and we'll generate a code for you"
            component={Input}
            type="text"
            className="w-40"
          />
        </div>
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Custom message"
            name="item_configuration.custom_message"
            component={Textarea}
            type="text"
            className="w-40"
          />
        </div>
      </div>
    </>
  )
}

const SetVoucherOptionsStepWithoutDeliveryOptions = () => {
  const { order: { currency } = {} } = useCurrentOrder()
  const [{ value }] = useField('offering_name')
  const [{ value: voucherTypeId }] = useField('offering_id')
  const { data: { data: vouchers = [] } = {} } = useVoucherTypes({
    perPage: -1,
  })

  const voucherType = useMemo(
    () => vouchers.find((voucher) => voucher.id === voucherTypeId),
    [vouchers, voucherTypeId]
  )

  return (
    <>
      <div className="font-bold mb-2">Set details</div>
      <div className="text-sm text-gray-500 mb-2">{`You are setting details for voucher ${value}`}</div>
      <div className="flex flex-col space-y-4">
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Recipient name"
            name="item_configuration.recipient_name"
            component={Input}
            type="text"
            autoFocus
          />
        </div>
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Recipient email"
            name="item_configuration.recipient_email"
            component={Input}
            type="email"
          />
        </div>
        {voucherType && voucherType.customisable_amount && (
          <div className="w-3/4 flex-1 min-w-0">
            <ConnectedField
              label="Voucher amount"
              name="item_configuration.amount"
              helperText="Optionally change the amount of this voucher"
              component={FormikCurrencyInput}
              currency={currency}
              type="text"
              className="w-32"
            />
          </div>
        )}
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Custom voucher code"
            name="item_configuration.predetermined_code"
            helperText="Leave this blank and we'll generate a code for you"
            component={Input}
            type="text"
            className="w-40"
          />
        </div>
        <div className="w-3/4 flex-1 min-w-0">
          <ConnectedField
            label="Custom message"
            name="item_configuration.custom_message"
            component={Textarea}
            type="text"
            className="w-40"
          />
        </div>
      </div>
    </>
  )
}

const ItemsList = ({ items, selectedId, onSelect }) => {
  return (
    <ul>
      {items.map((item) => (
        <li
          className="flex items-center space-x-2 py-3 px-2 cursor-pointer hover:bg-gray-50"
          onClick={() => onSelect(item)}
          key={item.id}
        >
          <div>
            <input
              id={`item-${item.id}`}
              name="offeringId"
              type="radio"
              checked={selectedId === item.id}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer"
              onChange={() => onSelect(item)}
            />
          </div>
          <div className="text-sm flex-grow">{item.name}</div>
        </li>
      ))}
    </ul>
  )
}

const LoadingVouchersSkeleton = () => (
  <div className="flex flex-col space-y-2 animate-pulse">
    <div className="h-12 bg-gray-200" />
    <div className="h-12 bg-gray-200" />
    <div className="h-12 bg-gray-200" />
    <div className="h-12 bg-gray-200" />
    <div className="h-12 bg-gray-200" />
    <div className="h-12 bg-gray-200" />
    <div className="h-12 bg-gray-200" />
  </div>
)
