import { useRequestMandateForMembership } from 'api/Memberships'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { Text } from 'ui/components/Text'
import { ConfirmationModal } from 'ui/Modal'

export const RequestMandateModal = ({ isOpen, onClose, membershipId }) => {
  const { isLoading, mutate } = useRequestMandateForMembership()
  const notification = useNotificationContext()
  const handleSend = () => {
    mutate(membershipId, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Payment method request sent"
            description={`An email was sent to the client with a link to set up their payments`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        onClose()
        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Request payment method"
      onClose={onClose}
      onConfirm={handleSend}
      confirming={isLoading}
      confirmButtonLabel="Send payment method request"
      variant="info"
    >
      <Text type="caption">
        This will send an email to the client containing a link for them to set up payments.
      </Text>
    </ConfirmationModal>
  )
}
