import { useMutation } from 'react-query'

const baseUrl = `${import.meta.env.VITE_APP_BASE_API_URL}`

export const useLoginAsSite = () =>
  useMutation(async ([siteId]: [string]) => {
    const req = await fetch(baseUrl + `/login-as-site`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ site_id: siteId }),
    })

    if (req.status > 400) throw req
  })

export const useSwitchBack = () =>
  useMutation(async () => {
    const req = await fetch(baseUrl + `/login-as-site`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    })

    if (req.status > 400) throw req
  })
